<template>
    <div class="teaching-research-course-schedule-temp">
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div
                v-if="!loadingShow"
                class="content-main"
            >
                <global-page-back
                    detailPageTitle="返回工作台"
                    @handlerGlobalType="handleCloseMyCurriculum"
                >
                    <template #globalPageBackTabs>
                        <div class="filter_class">
                            <filter-wrapper
                                :queryList="queryList"
                                :statusList="statusList"
                                :schedulingClassesList="schedulingClassesList"
                                :teacherList="teacherList"
                                :formData="formData"
                                @handleChangeCourseType="handleChangeCourseType"
                                @handlerChangeStatus="handlerChangeStatus"
                                @changeCascader="changeCascader"
                                @handlerScheduleClassIdChange="
                                    handlerScheduleClassIdChange
                                "
                                @handlerClassTeacherChange="
                                    handlerClassTeacherChange
                                "
                                @handlerGradeChange="handlerGradeChange"
                            >
                            </filter-wrapper>
                        </div>
                    </template>
                </global-page-back>
                <course-schedule-view
                    v-loading="getDataLoading"
                    :getDataLoading="getDataLoading"
                    :classAndTeacher="classAndTeacher"
                    :queryList="queryList"
                    :tableData="tableData"
                    :courseList="courseList"
                    :weekList="weekList"
                    :dayList="dayList"
                    :isTourClass="isTourClass"
                    :type="queryList.courseType"
                    :courseScheduleDetailSubjectsList="
                        courseScheduleDetailSubjectsList
                    "
                    :courseScheduleDetailTeachersList="
                        courseScheduleDetailTeachersList
                    "
                    @detailDay="detailDay"
                    @goToDetail="goToDetail"
                >
                </course-schedule-view>
            </div>
        </div>
        <error
            v-else
            :errorShow="errorShow"
        ></error>
        <!-- 详情/编辑 -->
        <dialog-wrapper
            v-if="dialogDetailsEditor.dialogVisible"
            :dialogObj="dialogDetailsEditor"
            @handleClose="handleCloseClassDetailsEditor"
        >
            <course-schedule-detail
                ref="courseScheduleDetailWrapper"
                :queryList="queryList"
                :comPointer="comPointer"
                :dayDetail="dayDetail"
                :repeatClassDisabled="repeatClassDisabled"
                :courseScheduleDetail="courseScheduleDetail"
                :courseScheduleDetailSubjectsList="
                    courseScheduleDetailSubjectsList
                "
                :courseScheduleDetailTeachersList="
                    courseScheduleDetailTeachersList
                "
                :courseScheduleDetailSupervisingTeachersList="
                    courseScheduleDetailSupervisingTeachersList
                "
                :type="queryList.courseType"
                :courseScheduleDetailRules="courseScheduleDetailRules"
                :schedulingClassesListFilter="schedulingClassesList"
                :showOption="showOption"
                @handlerRepeatClassChange="handlerRepeatClassChange"
                @datePickerChange="datePickerChange"
                @changeCascader="changeCascader"
            ></course-schedule-detail>
        </dialog-wrapper>
    </div>
</template>
<script>
import {
    // 功能组件
    Loading,
    Error,
    DialogWrapper,
    // 工具函数
    debounce,
} from "common-local";
import {downloadFile, listToTree, hasPermission} from "@/libs/utils.js";
import {formatTreeData} from "@/libs/utils";
import FilterWrapper from "./FilterData.vue";
import CourseScheduleView from "./CourseScheduleView.vue";
import CourseScheduleDetail from "./CourseScheduleDetail.vue";
import {mapState} from "vuex";

import {CourseScheduleTemp} from "@/operations/teachingResearchCourseScheduleTemp.js";
import {TeachingResearchCourseScheduleTempModel} from "@/models/TeachingResearchCourseScheduleTemp.js";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";

// 导入弹窗件
export default {
    name: "TeachingResearchCourseScheduleTempScrollWrapper",
    components: {
        Loading,
        Error,
        DialogWrapper,
        FilterWrapper,
        CourseScheduleView,
        CourseScheduleDetail,
        GlobalPageBack,
    },
    data() {
        return {
            loding: true, //切换课表类型
            errorShow: false,
            loadingShow: false,
            getDataLoading: false,
            queryList: {
                schoolId: "",
                productId: "",
                dayWeek: "week",
                isTourClass: "",
                startTime: "",
                endTime: "",
                selectDay: "",
                classTeacherId: "",
                courseType: "2",
                scheduleClassId: "",
                scheduleGradeId: "", // 年级
            },
            statusList: [
                {
                    id: "day",
                    label: "日",
                },
                {
                    id: "week",
                    label: "周",
                },
            ],
            emptydata: {
                scheduleClassId: "",
                emptyvalue: "1",
                emptyclass: "",
                emptydate: "",
            },
            emptyDataRules: {
                //   emptyvalue : [{ required: true, message: "请选择清空方式", trigger: "blur" }],
                emptyclass: [
                    {required: true, message: "请选择班级", trigger: "blur"},
                ],
                emptydate: [
                    {required: true, message: "请选择日期", trigger: "blur"},
                ],
            },
            PickerDate: {
                type: "datePick",
                label: "",
                startPlaceholder: "开始日期",
                endPlaceholder: "结束日期",
                key: "endTime",
                value: "",
                // picker: true,
                // format: 'yyyy-MM-dd HH:mm:ss'
            },
            /**
             * 筛选数据
             * schedulingClassesList 班级
             * schedulingClassesListFilter 筛选班级
             * teacherList 教师
             * supervisingTeacherList 督导教师
             * */
            schedulingClassesList: [],
            schedulingClassesListFilter: [],
            teacherList: [],
            supervisingTeacherList: [],
            filterQueryList: {
                schoolId: "",
                productId: "",
                dayWeek: "week",
                isTourClass: "",
                startTime: "",
                endTime: "",
                selectDay: "",
                classTeacherId: "",
                courseType: "2",
                scheduleClassId: "",
                scheduleGradeId: "", // 年级
            },
            /**
             * 根据年级查询课程时间 conditionList
             * 上课时间表,左侧渲染时间 courseList
             * */
            conditionList: {
                schoolId: "",
                productId: "",
                classId: "",
                isArrange: "",
                teacherId: "",
                gradeId: "",
            },
            courseList: [],
            weekList: [],
            dayList: [],
            tableData: [],
            dialogDetailsEditor: {
                title: "课程表",
                dialogVisible: false,
                width: "600px",
            },
            duplicate: "1",
            checkClassPatrolList: [],
            classPatrolList: [],
            tourDataList: [],
            showOption: true,
            classAndTeacher: {
                className: "",
                teacherName: "",
            },
            comPointer: "detail",
            dayDetail: false,
            repeatClassDisabled: false,
            courseScheduleDetail: {
                schoolId: "",
                productId: "",
                classArrangement: "",
                repeatType: "",
                repeatClass: "1", // 重复排课方式0不重复1每周重复2单周重复3双周重复(编辑)
                startScheduleTime: "",
                endScheduleTime: "",
                id: "", // 课程id
                weekAndNode: "", //周天+节日
                gradeClassName: "", //年级班级
                courseType: "", // 课表类型
                scheduleClassId: "", // 排课班级id（详情）
                classdata: "", //下拉列表班级数据
                scheduleClassName: "", // 排课班级名字（详情）
                scheduleClassName: "", // 排课班级名字（详情）
                subjectId: "", // 科目id（编辑）
                classTeacherId: "", // 上课教师id（编辑）
                supervisingTeacherId: "", // 督导教师id(编辑)
                scheduleDate: "",
                coursedate: "",
                scheduleTime: "", // 上课时间（详情）
                className: "", // 排课班级名称（详情）
                teacherName: "", // 上课教师（详情）
                subjectName: "", // 科目（详情）
                supervisingTeacherName: [], // 督导教师（详情）
                list: [
                    {
                        week: "",
                        scheduleDate: "",
                        restTimeId: "",
                    },
                ],
                _time: "", // 详情显示
            },
            courseScheduleDetailRules: {
                repeatClass: [
                    {
                        required: true,
                        message: "请选择重复方式",
                        trigger: "change",
                    },
                ],
                coursedate: [
                    {
                        required: true,
                        message: "请选择日期",
                        trigger: ["blur", "change"],
                    },
                ],
                subjectId: [
                    {required: true, message: "请选择科目", trigger: "change"},
                ],
                classTeacherId: [
                    {
                        required: true,
                        message: "请选择上课教师",
                        trigger: "change",
                    },
                ],
                // supervisingTeacherId: [
                //     { required: true, message: '请选择督导教师', trigger: ['blur', 'change'] },
                // ]
            },
            courseScheduleDetailSubjectsList: [],
            courseScheduleDetailTeachersList: [],
            courseScheduleDetailSupervisingTeachersList: [],
            recursionList: [],
            /**
             * 指针数据
             * scheduleClassIdPointer 年级指针，新增课程时
             *
             * */
            scheduleClassIdPointer: "",
            /**
             * tourDetailsData 巡课详情数据
             */
            tourDetailsData: {},
            tips: [],

            formData: {
                btnFormType: true,
                defaultclassid: "",
                data: [
                    {
                        placeholder: "选择班级/级联选择",
                        type: "cascader",
                        key: "organId",
                        value: "",
                        organType: "",
                        list: [],
                        cascaderProps: {
                            label: "name",
                            multiple: false,
                            value: "id",
                            checkStrictly: false,
                            emitPath: false,
                        },
                        filter(data) {
                            return data.organType !== "1";
                        },
                    },
                ],
            },
            selectChectArr: [],
            selectCourseIds: "",
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            isTourClass: (state) => state.isTourClass,
            teacherId: (state) => state.teacherId,
        }),
    },
    created() {
        this.init();
    },
    methods: {
        handleCloseMyCurriculum() {
            this.$emit("closeMyCurriculum");
        },
        /**
         * @Description: 初始化函数
         * @DoWhat: 初始化函数
         * @UseScenarios: 初始化页面
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        init() {
            this.initPageAssignment();
            this.initPageData();
        },
        /**
         * @Description: 初始化页面赋值
         * @DoWhat: 初始化页面赋值
         * @UseScenarios: 初始化页面赋值
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        initPageAssignment() {
            const _this = this;
            const courseScheduleTemp = new CourseScheduleTemp();

            this.queryList.schoolId = this.schoolId;
            this.queryList.productId = "cloud_campus";

            this.filterQueryList.schoolId = this.schoolId;
            this.filterQueryList.productId = "cloud_campus";
            // 排课星期请求赋值
            this.conditionList.schoolId = this.schoolId;
            this.conditionList.productId = "cloud_campus";
            this.conditionList.isArrange = "1";

            // 新增课程/编辑
            this.courseScheduleDetail.schoolId = this.schoolId;
            this.courseScheduleDetail.productId = "cloud_campus";
            this.courseScheduleDetail.classArrangement = "1";
            this.courseScheduleDetail.repeatType = "1";

            this.queryList.selectDay = courseScheduleTemp.getTimeDay(_this);
            // console.log(this.queryList.selectDay, "this.queryList.selectDay");
        },
        /**
         * @Description: 初始化页面数据
         * @DoWhat: 初始化页面数据
         * @UseScenarios: 初始化页面数据
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        async initPageData() {
            this.loadingShow = true;
            this.getDataLoading = true;
            const teachingResearchCourseScheduleTempModel =
                new TeachingResearchCourseScheduleTempModel();
            // 科目列表
            await teachingResearchCourseScheduleTempModel
                .getSchoolSubjectsList({
                    schoolId: this.schoolId,
                    dictKey: "teachingSubjects",
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        // console.log(res.data.data, "科目列表");
                        this.courseScheduleDetailSubjectsList = res.data.data;
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            // 班级列表（日/周 新增课程的时候，下拉框）
            // await teachingResearchCourseScheduleTempModel
            //     .getSchoolClassList({schoolId: this.schoolId})
            //     .then((res) => {
            //         if (res.data.code == "200") {
            //             this.sedClassesList = res.data.data;
            //             console.log('/////',this.sedClassesList);
            //         } else if (res.data.msg) {
            //             this.$message.error(res.data.msg);
            //         }
            //     });
            // 获取班级教师查看班级列表
            await teachingResearchCourseScheduleTempModel
                .getGradeOfTreeByUser({schoolId: this.schoolId})
                .then((res) => {

                    if (res.data.code == "200") {
                        const index = this.formData.data.findIndex(
                            (i) => i.type === "cascader",
                        );
                        console.log('res.data.data',res.data.data);

                        if (Object.keys(res.data.data).length != 0) {
                            let localData = formatTreeData(
                                res.data.data,
                                "id",
                                "parentOrg",
                            );
                            let newlocaldata = [];
                            for (
                                let index = 0;
                                index < localData.length;
                                index++
                            ) {
                                const item = localData[index];
                                if (item.children) {
                                    newlocaldata.push(item);
                                }
                            }

                            this.schedulingClassesList = newlocaldata;
                            this.getFindClassList(this.schedulingClassesList);
                            this.formData.data[index].list = newlocaldata;

                            this.courseScheduleDetail.classdata =
                                this.formData.data[index];
                            this.getClassTeacherIdForList(
                                this.courseScheduleDetail.classdata.list,
                            );
                        }
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });

            // 教师列表
            await teachingResearchCourseScheduleTempModel
                .getSchoolTeacherList({
                    schoolId: this.schoolId,
                    applyPerson: "1",
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        this.setDisabledOption(res.data.data);
                        // console.log(res.data.data, "res.data.data 教师列表");
                        this.teacherList = res.data.data;
                        this.courseScheduleDetailTeachersList = res.data.data;
                        this.courseScheduleDetailSupervisingTeachersList =
                            res.data.data;
                        // console.log(
                        //     this.teacherList,
                        //     "this.teacherList教师数据",
                        // );
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });

            await this.getFindTeacher(this.teacherList);
            // 请求上课时间
            await this.reClassTime();
        },

        /**
         * @Description: 获取最后一级的scheduleClassId
         * @DoWhat:获取最后一级的scheduleClassId
         * @UseScenarios: 获取最后一级的scheduleClassId
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        getClassTeacherIdForList(list) {
            let loallist = list[0];
            if (loallist.children && loallist.children.length > 0) {
                this.getClassTeacherIdForList(loallist.children);
            } else {
                this.queryList.scheduleClassId = loallist.id;
                this.formData.defaultclassid = loallist.id;
            }
        },
        /**
         * @Description: 获取上课时间
         * @DoWhat: 获取上课时间
         * @UseScenarios: 获取上课时间
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        async reClassTime() {
            // console.log(this.queryList, "请求课程表");
            const teachingResearchCourseScheduleTempModel =
                new TeachingResearchCourseScheduleTempModel();
            this.conditionList.classId = this.queryList.scheduleClassId;
            this.conditionList.teacherId = this.queryList.classTeacherId;
            this.courseScheduleDetail.classTeacherId =
                this.queryList.classTeacherId;
            // console.log(
            //     this.conditionList.gradeId,
            //     "请求课程表+++ this.conditionList",
            //     this.conditionList.teacherId
            // );
            await teachingResearchCourseScheduleTempModel
                .getClassWeek(this.conditionList)
                .then((res) => {
                    this.courseList = [];
                    if (res.data.code == "200") {
                        // console.log(res.data, "上课星期数据");
                        res.data.data.forEach((item) => {
                            let obj = {
                                id: item.id,
                                label: `${item.name}`,
                                time: `${item.startTime}-${item.endTime}`,
                                starTime: item.startTime,
                                gradeId: item.gradeId,
                                gradeName: item.gradeName,
                            };
                            this.courseList.push(obj);
                        });

                        this.changeQuery();
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            this.classnameandteacher();
        },
        async classnameandteacher() {
            // 获取班级和老师
            const teachingResearchCourseScheduleTempModel =
                new TeachingResearchCourseScheduleTempModel();
            let postdic;
            if (this.queryList.courseType === "1") {
                postdic = {
                    classId: this.queryList.scheduleClassId,
                };
            } else if (this.queryList.courseType === "2") {
                postdic = {
                    teacherId: this.queryList.classTeacherId,
                };
            } else if (this.queryList.courseType === "3") {
                postdic = {
                    classId: this.queryList.scheduleGradeId,
                };
            }
            // console.log('postdicpostdicpostdicpostdic',postdic);
            await teachingResearchCourseScheduleTempModel
                .getClassNameTeacherData(postdic)
                .then((res) => {
                    let teacherNames = res.data.data.teacherNames;
                    let teacherNameStr;
                    if (this.queryList.courseType === "1") {
                        if (teacherNames.length > 0) {
                            teacherNameStr = teacherNames[0];
                            for (var i = 1; i < teacherNames.length; i++) {
                                teacherNameStr =
                                    teacherNameStr + "、" + teacherNames[i];
                            }
                        } else {
                            teacherNameStr = "-";
                        }
                    } else {
                        teacherNameStr = res.data.data.teacherName;
                    }

                    this.classAndTeacher = {
                        className: res.data.data.className,
                        teacherName: teacherNameStr,
                    };
                    if (this.queryList.courseType == "1") {
                        this.courseScheduleDetail.gradeClassName =
                            res.data.className;
                    }
                    // console.log(res.data,classAndTeacher,'数据-----老师')
                });
        },
        /**
         * @Description: 写入禁用项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        setDisabledOption(list) {
            list.forEach((item) => {
                if (item.key !== "teacher") {
                    this.$set(item, "disabled", true);
                } else {
                    this.$set(item, "disabled", false);
                }

                if (item.children && item.children.length > 0) {
                    this.setDisabledOption(item.children);
                }
            });
        },
        /**
         * @Description: 获取页面数据
         * @DoWhat: 获取页面数据
         * @UseScenarios: 获取页面数据
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        getList() {
            const teachingResearchCourseScheduleTempModel =
                new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel
                .getCourseScheduleList()
                .then((res) => {
                    // console.log(res, "res");
                });
        },
        changeQuery() {
            if (this.queryList.courseType == "3") return;
            const _this = this;
            const teachingResearchCourseScheduleTempModel =
                new TeachingResearchCourseScheduleTempModel();
            const courseScheduleTemp = new CourseScheduleTemp();

            this.getDataLoading = true;
            courseScheduleTemp.sortingQueryList(
                this.queryList,
                this.filterQueryList,
                _this,
            );
            teachingResearchCourseScheduleTempModel
                .getSchoolCourseListByAuth(this.filterQueryList)
                .then((res) => {
                    if (res.data.code == "200") {
                        this.getDataLoading = false;
                        console.log("2222222222", res.data.data);
                        this.tableData = res.data.data;
                        if (this.tableData.length == 0) {
                            this.courseList = [];
                        }
                        switch (_this.queryList.dayWeek) {
                            case "day":
                                _this.dayList = [{label: "", child: []}];
                                courseScheduleTemp.getDayList(
                                    _this.filterQueryList,
                                    res.data.data,
                                    _this.dayList,
                                    _this.courseList,
                                    _this,
                                );
                                break;
                            case "week":
                                _this.weekList = [];
                                courseScheduleTemp.getWeekList(
                                    _this.filterQueryList,
                                    res.data.data,
                                    _this.weekList,
                                    _this.courseList,
                                    _this
                                );
                                break;
                            default:
                                break;
                        }
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingShow = false;
                    this.getDataLoading = false;
                });
        },
        /**
         * @Description: 选择课程类型
         * @DoWhat: 选择课程类型
         * @UseScenarios: 头部筛选
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        handleChangeCourseType(e) {
            // console.log("选择课程类型", this.queryList)
            this.queryList.courseType = e;
            this.courseScheduleDetail.courseType = e;
            this.loding = false;

            switch (this.queryList.courseType) {
                case "1":
                    this.conditionList.gradeId = ""; // 清除年级赋值
                    this.queryList.scheduleGradeId = ""; // 清除年级ID
                    this.queryList.classTeacherId = ""; // 清除教师ID
                    // this.getFindClassList1 (this.schedulingClassesList)
                    this.reClassTime();
                    break;
                case "2":
                    this.conditionList.gradeId = ""; // 清除年级赋值
                    this.queryList.scheduleGradeId = ""; // 清除年级ID
                    this.queryList.classTeacherId = ""; // 清除教师ID
                    this.getFindTeacher(this.courseScheduleDetailTeachersList); // 教师课表，教师回显
                    this.reClassTime();

                    break;
                default:
                    break;
            }
            setTimeout(() => {
                this.loding = true;
            }, 500);

            this.classnameandteacher();
        },
        /**
         * @Description: 选择清空方式
         * @DoWhat: 选择清空方式
         * @UseScenarios: 选择清空方式
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        emptyChange(value) {
            this.emptydata.emptyvalue = value;
        },
        /**
         * @Description: 选择日周
         * @DoWhat: 选择日周
         * @UseScenarios: 头部筛选
         * @Attention: 无
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        handlerChangeStatus(data) {
            this.queryList.dayWeek = data.id;
            this.changeQuery();
            this.reClassTime();
        },
        // 选择日期
        datePickerChange(value) {
            // console.log("----选择日期", this.emptydata.emptydate);
            this.courseScheduleDetail.startScheduleTime = value[0];
            this.courseScheduleDetail.endScheduleTime = value[1];
        },
        // 新增/编辑选择的重复方式
        handlerRepeatClassChange(val) {
            this.courseScheduleDetail.repeatClass = val;
            // 如果是重复方式是-不重复就用点击的课程日期
            if (val == "0") {
                // console.log("重复", val);
                this.courseScheduleDetail.startScheduleTime =
                    this.courseScheduleDetail.scheduleTime;
                this.courseScheduleDetail.endScheduleTime =
                    this.courseScheduleDetail.scheduleTime;

                this.courseScheduleDetail.coursedate = [
                    this.courseScheduleDetail.scheduleTime,
                    this.courseScheduleDetail.scheduleTime,
                ];
            } else {
                this.courseScheduleDetail.coursedate = ["", ""];
            }
        },
        /**
         * @Description: 选择日期
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-10-18 16:42:25
         */
        changeCascader(data) {
            // console.log(data, data.ref.getCheckedNodes(), "===========");
            if (data.data.cascaderProps.multiple) {
                let nodes = data.ref.getCheckedNodes();
                let nodeidstr = "";
                if (nodes.length > 0) {
                    // nodeidstr = nodes[1].value;
                    for (var i = 0; i < nodes.length; i++) {
                        let nodeitem = nodes[i];

                        if (nodeitem.children.length == 0) {
                            nodeidstr = nodeidstr + "," + nodes[i].value;
                        }
                    }
                }
                nodeidstr = nodeidstr.slice(1);
                this.emptydata.emptyclass = nodeidstr;
                this.emptydata.scheduleClassId = nodeidstr; //多选
                this.courseScheduleDetail.classTeacherId =
                    this.queryList.classTeacherId;
            } else if (data.ref.getCheckedNodes()[0]) {
                let node;
                node = data.ref.getCheckedNodes()[0].data;
                this.queryList.scheduleClassId = node.id;
                this.courseScheduleDetail.classTeacherId =
                    this.queryList.classTeacherId;
                this.reClassTime();
                this.classnameandteacher();
            } else {
                this.queryList.scheduleClassId = "";
            }
            // console.log("级联菜单id", this.queryList.scheduleClassId);
        },

        /**
         * @Description: 日，详情
         * @DoWhat: 日，详情
         * @UseScenarios: 日，详情
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 14:56:42
         */
        detailDay(detailDaydata) {
            // console.log(detailDaydata, "日，详情");
            let data = detailDaydata.data;
            // 循环级部/年级/班级
            this.getNewClassDropDown(this.schedulingClassesList, data._gradeId);

            let subject = detailDaydata.daylist.child[detailDaydata.idx];
            if (this.queryList.courseType === 2) {
                this.courseScheduleDetail.weekAndNode =
                    detailDaydata.daylist.label.substr(-2) + " | " + "作息";
            } else {
                this.courseScheduleDetail.weekAndNode =
                    detailDaydata.daylist.label.substr(-2) +
                    " | " +
                    subject._uuName;
            }
            this.courseScheduleDetail._time = subject._time;
            this.courseScheduleDetail.repeatClass = data.repeatClass + "";
            this.courseScheduleDetail.subjectId = data.subjectId;

            if (
                data.startScheduleTime === null ||
                data.startScheduleTime === "null" ||
                data.startScheduleTime === ""
            ) {
                this.courseScheduleDetail.coursedate = [
                    data.scheduleTime,
                    data.scheduleTime,
                ];
                // console.log(
                //     "周三---1111",
                //     this.courseScheduleDetail.coursedate,
                // );
            } else {
                this.courseScheduleDetail.coursedate = [
                    data.startScheduleTime,
                    data.endScheduleTime,
                ];
                // console.log("周三---", this.courseScheduleDetail.coursedate);
            }

            this.courseScheduleDetail.classTeacherId = data.classTeacherId;
            Object.keys(data).forEach((key) => {
                Object.keys(this.courseScheduleDetail).forEach((subKey) => {
                    if (key === subKey) {
                        this.courseScheduleDetail[key] = data[key];
                    }
                });
            });
            if (data.repeatClass != "0") {
                this.courseScheduleDetail.list[0].week = this.$moment(
                    this.courseScheduleDetail.scheduleTime,
                    "YYYY-MM-DD",
                ).format("E");
            } else {
                this.courseScheduleDetail.list[0].scheduleDate =
                    this.courseScheduleDetail.scheduleTime;
            }
            this.courseScheduleDetail.repeatClass =
                this.courseScheduleDetail.repeatClass + "";
            if (this.queryList.courseType == "3") {
                // 年级
                // this.courseScheduleDetail._timer = data.data.time;
                // this.courseScheduleDetail.list[0].restTimeId = data.data._id;
            } else {
                // 班级/教师
                this.courseScheduleDetail.list[0].restTimeId = data._uuid;
            }
            this.courseScheduleDetail.supervisingTeacherId =
                data.supervisingTeacherId.split(",");
            this.comPointer = "detail";
            this.dayDetail = true;
            this.dialogDetailsEditor.title = "详情";
            this.dialogDetailsEditor.dialogVisible = true;
        },
        /**
         * @Description: 清除新增/编辑弹窗数据
         * @DoWhat: 清除新增/编辑弹窗数据
         * @UseScenarios: 清除新增/编辑弹窗数据
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 15:19:07
         */
        clearPopUpEditingDetailsData() {
            this.courseScheduleDetail.className = "";
            if (this.queryList.courseType != "2") {
                this.courseScheduleDetail.classTeacherId = "";
            }
            // this.courseScheduleDetail.courseType = '';
            this.courseScheduleDetail.id = "";
            // this.courseScheduleDetail.repeatClass = '0';
            this.courseScheduleDetail.repeatClass = "1";
            this.courseScheduleDetail.coursedate = ""; //初始化日期
            this.courseScheduleDetail.list = [
                {restTimeId: "", scheduleDate: "", week: ""},
            ];
            // this.courseScheduleDetail.scheduleClassId = '';
            this.courseScheduleDetail.scheduleDate = "";
            this.courseScheduleDetail.scheduleTime = "";
            this.courseScheduleDetail.subjectId = "";
            this.courseScheduleDetail.subjectName = "";
            this.courseScheduleDetail.supervisingTeacherId = "";
            this.courseScheduleDetail.supervisingTeacherName = [];
            this.courseScheduleDetail.teacherName = "";
            this.courseScheduleDetail.startScheduleTime = "";
            this.courseScheduleDetail.endScheduleTime = "";
            this.courseScheduleDetail.repeatType = "1";
            this.dayDetail = false;
            this.comPointer = "detail";
            this.repeatClassDisabled = false;
        },

        /**
         * @Description: 获取班级列表
         * @DoWhat: 获取班级列表
         * @UseScenarios:
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2023-02-14 18:36:39
         * @param {*} list
         */
        getNewClassDropDown(list, gradeId) {
            list.forEach((item) => {
                if (gradeId == item.id) {
                    this.schedulingClassesListFilter = [item];
                }

                if (item.child && item.child.length > 0) {
                    this.getNewClassDropDown(item.child, gradeId);
                }
            });
        },
        getScheduleClassName(list) {
            let str = "";
            for (let i = 0; i < list.length; i++) {
                // console.log(list[i], "list[i].organType");
                if (list[i].organType == "4") {
                    str = list[i].id;
                    return str;
                }
                if (list[i].child && list[i].child.length > 0) {
                    return this.getScheduleClassName(list[i].child, str);
                }
            }
        },
        getClassName(list) {
            list.forEach((item) => {
                if (this.scheduleClassIdPointer == item.id) {
                    this.courseScheduleDetail.className = item.name;
                }
                if (item.child.length > 0) {
                    return this.getClassName(item.child);
                }
            });
        },
        /**
         * @Description: 点击进入详情
         * @DoWhat: 点击进入详情
         * @UseScenarios: 点击进入详情
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-10 09:46:34
         */
        goToDetail(data) {
            console.log("data", data);
            // 循环级部/年级/班级
            this.getNewClassDropDown(
                this.schedulingClassesList,
                data.item._gradeId,
            );

            Object.keys(data.item).forEach((key) => {
                Object.keys(this.courseScheduleDetail).forEach((subKey) => {
                    if (key === subKey) {
                        this.courseScheduleDetail[key] = data.item[key];
                    }
                });
            });

            if (this.queryList.courseType == "3") {
                this.courseScheduleDetail._timer = data.data.time;
            }

            if (
                typeof this.courseScheduleDetail.supervisingTeacherId ===
                "string"
            ) {
                this.courseScheduleDetail.supervisingTeacherId =
                    this.courseScheduleDetail.supervisingTeacherId.split(",");
            }

            if (data.item.repeatClass != "0") {
                this.courseScheduleDetail.list[0].week = data.index + 1 + "";
            } else {
                this.courseScheduleDetail.list[0].scheduleDate =
                    this.courseScheduleDetail.scheduleTime;
            }

            if (this.queryList.courseType == "3") {
                // 年级
                // this.courseScheduleDetail._timer = data.data.time;
                this.courseScheduleDetail.list[0].restTimeId = data.data._id;
            } else {
                // 班级/教师
                this.courseScheduleDetail.list[0].restTimeId = data.item._uuid;
            }

            this.courseScheduleDetail.repeatClass =
                this.courseScheduleDetail.repeatClass + "";
            if (
                this.courseScheduleDetail.startScheduleTime === null ||
                this.courseScheduleDetail.startScheduleTime == "null" ||
                this.courseScheduleDetail.startScheduleTime === ""
            ) {
                this.courseScheduleDetail.startScheduleTime =
                    this.courseScheduleDetail.scheduleTime;
                this.courseScheduleDetail.endScheduleTime =
                    this.courseScheduleDetail.scheduleTime;
            }
            this.courseScheduleDetail.weekAndNode =
                data.data.label.slice(0, 2) + " | " + data.item._uuName;
            this.courseScheduleDetail.coursedate = [
                this.courseScheduleDetail.startScheduleTime,
                this.courseScheduleDetail.endScheduleTime,
            ];

            // console.log(
            //     data.item._timestamp >= new Date().getTime(),
            //     "data.item._timestamp >= new Date().getTime()",
            // );

            this.dayDetail = !(data.item._timestamp >= new Date().getTime());
            console.log(this.courseScheduleDetail, "this.courseScheduleDetail");

            this.comPointer = "detail";
            this.dialogDetailsEditor.title = "详情";
            this.dialogDetailsEditor.dialogVisible = true;
        },

        getTeach(supervisingTeacherList, supervisingTeacherId, selectT) {
            supervisingTeacherList.forEach((subItem) => {
                if (supervisingTeacherId == subItem.id) {
                    let obj = {
                        id: subItem.id,
                        teacherName: subItem.name,
                    };

                    selectT.push(obj);
                }

                if (subItem.children && subItem.children.length > 0) {
                    this.getTeach(
                        subItem.children,
                        supervisingTeacherId,
                        selectT,
                    );
                }
            });
        },
        getTeach1(supervisingTeacherList, data, selectT) {
            supervisingTeacherList.forEach((subItem) => {
                data.forEach((item) => {
                    if (item.supervisingTeacherId == subItem.id) {
                        let obj = {
                            id: subItem.id,
                            teacherName: subItem.name,
                        };

                        selectT.push(obj);
                    }
                });

                if (subItem.children && subItem.children.length > 0) {
                    this.getTeach1(subItem.children, data, selectT);
                }
            });
        },

        /**
         * @Description: 关闭
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date: 2022-11-10 10:31:04
         */
        handleCloseClassDetailsEditor() {
            this.dialogDetailsEditor.dialogVisible = false;
            setTimeout(() => {
                this.clearPopUpEditingDetailsData();
            }, 200);
        },
        /**
         * @Description: 获取列表最新数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-02-02 09:02:50
         */
        getNewTableList() {
            // console.log("123");
            switch (this.queryList.courseType) {
                case "1":
                case "2":
                    this.changeQuery();
                    break;
                case "3":
                    this.gradeTableList();
                    break;
                default:
                    break;
            }
        },
        // 详情操作按钮相关
        /**
         * @Description: 获取班级名称
         * @DoWhat: 获取班级名称
         * @UseScenarios: 获取班级名称
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-24 16:38:25
         */
        getFindClassList(list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList(list[i].child);
                    break;
                } else if (list[i]) {
                    this.queryList.scheduleClassId = list[i].id;
                    // console.log('this.queryList.scheduleClassId------------------',this.queryList.scheduleClassId);
                    // console.log(list[i].name, "list[i]---00");
                    break;
                }
            }
        },
        getFindClassList1(list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList(list[i].child);
                    break;
                } else if (list[i]) {
                    this.queryList.scheduleClassId = list[i].id;
                    this.courseScheduleDetail.scheduleClassId = list[i].id;
                    break;
                }
            }
        },
        getFindClassList2(list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].child.length > 0) {
                    this.getFindClassList(list[i].child);
                    break;
                } else if (list[i]) {
                    this.courseScheduleDetail.scheduleClassId = list[i].id;
                    break;
                }
            }
        },
        /**
         * @Description: 获取教师名称
         * @DoWhat: 获取教师名称
         * @UseScenarios: 获取教师名称
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-24 17:21:26
         */
        getFindTeacher(list) {
            list.forEach((v) => {
                if (v.children) {
                    this.getFindTeacher(v.children);
                }
            });
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == this.teacherId) {
                    // console.log('list[i]',list[i]);
                    this.queryList.classTeacherId = list[i].id;
                    this.courseScheduleDetail.classTeacherId = list[i].id;
                    // console.log('this.queryList.classTeacherId',this.queryList.classTeacherId);
                    break;
                }
            }
        },

        /**
         * @Description: 集中处理函数，某些值改变则改变赋值操作
         * @DoWhat: 集中处理函数，某些值改变则改变赋值操作
         * @UseScenarios: 集中处理函数，某些值改变则改变赋值操作
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 09:21:39
         */
        assignmentOperations() {
            this.scheduleClassIdPointer = this.queryList.scheduleClassId;
            // console.log(this.scheduleClassIdPointer, "年级指针");
        },
        /**
         * @Description: 选择班级变化，请求新的作息时间表
         * @DoWhat: 选择班级变化
         * @UseScenarios: 选择班级变化
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-16 09:31:31
         */
        handlerScheduleClassIdChange() {
            // console.log("年级变化", this.queryList.scheduleClassId);
            this.assignmentOperations();
            this.reClassTime("loading");
        },
        /**
         * @Description: 更改班级
         * @DoWhat: 更改班级
         * @UseScenarios: 更改班级
         * @Attention: 无
         * @Author: 武东超
         * @Date: 2022-11-21 17:04:42
         */
        handlerClassTeacherChange() {
            this.courseScheduleDetail.classTeacherId =
                this.queryList.classTeacherId;
            this.reClassTime();
        },
        /**
         * @Description: 更改年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-01-31 19:06:25
         */
        handlerGradeChange() {
            this.courseList = [];
            // console.log(this.queryList.scheduleGradeId, "年级");
            const teachingResearchCourseScheduleTempModel =
                new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel
                .getSpliceOrganList({
                    schoolId: this.schoolId,
                    id: this.queryList.scheduleGradeId,
                    jobScope: [1, 2, 3],
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        res.data.data.forEach((item) => {
                            // console.log(item, "item");
                            let obj = {
                                id: item.id,
                                label: `${item.name}`,
                            };
                            this.courseList.push(obj);
                        });
                        // console.log(res.data.data, "根据年级获取班级");
                        this.getClassTime();
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        getClassTime() {
            const teachingResearchCourseScheduleTempModel =
                new TeachingResearchCourseScheduleTempModel();
            this.conditionList.gradeId = this.queryList.scheduleGradeId;
            // console.log(
            //     this.queryList,
            //     this.conditionList,
            //     "年级课程表获取表头",
            // );
            teachingResearchCourseScheduleTempModel
                .getClassWeek(this.conditionList)
                .then((res) => {
                    this.weekList = [];
                    if (res.data.code == "200") {
                        // console.log(res.data.data, "年级上课星期数据");
                        res.data.data.forEach((item) => {
                            let obj = {
                                _id: item.id,
                                label: `${item.name}  ${item.startTime}-${item.endTime}`,
                                time: `${item.startTime}-${item.endTime}`,
                                starTime: item.startTime,
                                gradeId: item.gradeId,
                                endTime: `${item.endTime}`,
                            };
                            this.weekList.push(obj);
                        });
                        // console.log(this.weekList, "this.weekList");
                        this.gradeTableList();
                    } else if (res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            this.classnameandteacher();
        },
        /**
         * @Description: 年级table列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-02-01 11:44:30
         */
        gradeTableList() {
            this.getDataLoading = true;
            this.queryList.dayWeek = "week"; // 表头展示多个

            this.filterQueryList.selectDay = this.queryList.selectDay;
            this.filterQueryList.courseType = "3";
            this.filterQueryList.startTime = this.queryList.selectDay;
            this.filterQueryList.endTime = this.queryList.selectDay;
            this.filterQueryList.scheduleGradeId =
                this.queryList.scheduleGradeId;
            // console.log(this.queryList, this.filterQueryList, "获取年级table");
            const teachingResearchCourseScheduleTempModel =
                new TeachingResearchCourseScheduleTempModel();
            teachingResearchCourseScheduleTempModel
                .getSchoolCourseListByAuth(this.filterQueryList)
                .then((res) => {
                    if (res.data.code == "200") {
                        let str = Object.prototype.toString,
                            type = "[object Object]",
                            len = this.weekList.length,
                            data = res.data.data;

                        for (let i = 0; i < len; i++) {
                            // console.log("进来来说");
                            this.$set(this.weekList[i], "id", i);
                            this.$set(
                                this.weekList[i],
                                "data",
                                this.queryList.selectDay,
                            );
                            this.$set(this.weekList[i], "child", []);
                        }

                        this.weekList.forEach((item, index) => {
                            this.courseList.forEach((subItem, subIndex) => {
                                let obj = {
                                    _uuid: subItem.id,
                                    _uuName: subItem.label,
                                    _time: this.filterQueryList.selectDay,
                                    _day: item.data,
                                    _class: subItem.id,
                                    _deadline: item.data + " " + item.starTime,
                                    _timestamp: new Date(
                                        item.data + " " + item.starTime,
                                    ).getTime(),
                                    _gradeId: item.gradeId,
                                    checkState: false,
                                    operateShow: false,
                                };
                                // console.log(obj, "obj班级");
                                item.child.push(obj);
                            });
                        });
                        if (str.call(data) !== type) {
                            this.weekList.forEach((item, index) => {
                                item.child.forEach((pItem, pIndex) => {
                                    data.forEach((subItem) => {
                                        if (
                                            item._id === subItem.restTimeId &&
                                            pItem._uuid ==
                                                subItem.scheduleClassId
                                        ) {
                                            item.child.splice(
                                                pIndex,
                                                1,
                                                Object.assign(subItem, pItem),
                                            );
                                        }
                                    });
                                });
                            });
                        }
                        // console.log(this.weekList, "this.weekList");
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }

                    this.getDataLoading = false;
                });
        },
    },
    watch: {
        "queryList.courseType": {
            handler() {
                /**
                 * 1. 课表类型变化时，新增课程表数据变化
                 * 2. 请求新的课程表接口
                 * */
                this.courseScheduleDetail.courseType =
                    this.queryList.courseType;
                if (this.queryList.courseType == "3") {
                    // console.log("3", this.queryList.selectDay);
                    this.gradeTableList();
                } else {
                    this.changeQuery();
                }
            },
            deep: true,
        },
        "queryList.classTeacherId": {
            handler() {
                this.changeQuery();
            },
            deep: true,
        },
        "queryList.selectDay": {
            handler() {
                if (this.queryList.courseType == "3") {
                    // console.log("3", this.queryList.selectDay);
                    this.gradeTableList();
                } else {
                    this.changeQuery();
                    this.reClassTime();
                }
            },
            deep: true,
        },
        "queryList.isTourClass": {
            handler() {
                if (this.queryList.courseType == "3") {
                    // console.log("3", this.queryList.selectDay);
                    this.gradeTableList();
                } else {
                    this.changeQuery();
                }
            },
            deep: true,
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .global-page-back {
    padding: 0;
}
.teaching-research-course-schedule-temp {
    box-sizing: border-box;
    width: 100%;
    .content-main {
        background-color: #fff;
        box-sizing: border-box;
        padding: 8px 16px 16px;
        border-radius: 8px;
    }
}
.filter_class {
    margin-left: 100px;
}
.options-btn {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    margin-top: 10px;
    // padding: 10px;
}
.btn-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}
.tips {
    display: flex;
    justify-content: center;
    padding: 0px 0px 20px 0;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #808487;
}
.request-pool {
    display: flex;
    flex-wrap: wrap;
    .pool {
        display: flex;
        margin: 0 10px;
        cursor: pointer;
    }
}
::v-deep {
    .el-dialog {
        .el-form-item--small .el-form-item__content {
            line-height: 18px;
            display: flex;
            align-items: center;
        }
    }
}
</style>
